import React from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { colors } from '@/lib/theme'
import { PortalWrapper, Wrapper } from '@/UI'
import { TableOfContents } from './TocContent'
import { Post } from '../../types'
import { MobileTocHead } from './MobileTocHead'
import { tocVisibleState } from './state'
import { topBlockShadow } from '@/blocks/layout/partials/common'

interface Props extends Pick<Post, 'content'> {}

export const TocMobile = ({ content }: Props) => {
  const { isVisible } = useSnapshot(tocVisibleState)
  const toggleHandler = () => {
    tocVisibleState.isVisible = !isVisible
  }
  const closeHandler = () => {
    tocVisibleState.isVisible = false
  }

  return (
    <Root $isOpened={isVisible}>
      <MobileTocHead isOpened={isVisible} onClick={toggleHandler} />
      <PortalWrapper closeHandler={closeHandler} isVisible={isVisible} offsetTop="178px" fillColor="transparency">
        <TocWrapper>
          <TableOfContents content={content} />
        </TocWrapper>
      </PortalWrapper>
    </Root>
  )
}

const Root = styled.section<{ $isOpened: boolean }>`
  display: grid;
  width: 100%;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.primary.primary};
  border-bottom: 1px solid ${({ $isOpened }) => ($isOpened ? colors.white : colors.primary.primary)};
  transition: border-bottom-color 0.2s ease-in-out;
`

const TocWrapper = styled(Wrapper)`
  padding-top: 8px;
  padding-bottom: 16px;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.primary.primary};
  box-shadow: ${topBlockShadow};
  max-height: 100%;
`

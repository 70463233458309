import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { siteUrl } from '_/config/site-config'
import type { IGatsbyImageData } from 'gatsby-plugin-image'
import { ArticleAuthors, AuthorType } from '@/UI'
import { colors, desktopSmallMedia, hoverMedia } from '@/lib/theme'

type HostType = {
  name: string
  slug: string
  avatar: {
    gatsbyImageData: IGatsbyImageData
  }
  miniavatar?: {
    gatsbyImageData: IGatsbyImageData
  }
}

interface Props {
  hosts: readonly HostType[]
  className?: string
}

export const Hosts = ({ hosts, className }: Props) => {
  const transformedHosts: AuthorType[][] = hosts.map(({ name, avatar, miniavatar, slug }) => [
    { name, img: miniavatar ? miniavatar.gatsbyImageData : avatar.gatsbyImageData, slug },
  ])

  if (!Array.isArray(hosts) || !hosts.length) return null

  return (
    <Root className={className}>
      {transformedHosts.map(item => (
        <LinkStyled to={`${siteUrl}/team/${item[0].slug}/`} key={item[0].name}>
          <ArticleAuthors authors={item} />
        </LinkStyled>
      ))}
    </Root>
  )
}

const Root = styled.div`
  display: grid;
  justify-content: start;
  grid-gap: 16px;
  padding: 24px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);

  ${desktopSmallMedia} {
    padding: unset;
    border: unset;
  }
`

const LinkStyled = styled(Link)`
  & span[datatype='author_name'] {
    transition: color 0.2s ease-in-out;
  }
  & div[datatype='author_img'] {
    transition: box-shadow 0.2s ease-in-out;
  }

  ${hoverMedia} {
    &:hover {
      & span[datatype='author_name'] {
        color: ${colors.primary.primary};
      }
      & div[datatype='author_img'] {
        box-shadow: 0 0 8px 2px ${colors.primary.primary};
      }
    }
  }

  &:active {
    span[datatype='author_name'] {
      color: ${colors.primary.darkest};
    }
    & div[datatype='author_img'] {
      box-shadow: none;
    }
  }
`

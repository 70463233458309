import React from 'react'
import { styled } from '@linaria/react'
import { colors, fontLeagueSpartan, hoverMedia } from '@/lib/theme'

interface Props {
  isOpened: boolean
  onClick?: () => void
}

export const MobileTocHead = ({ isOpened, onClick }: Props) => {
  return (
    <Root $isOpened={isOpened} onClick={onClick}>
      <Title>Table of contents</Title>
      <Indicator $isOpened={isOpened}>
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" stroke="currentColor" strokeWidth="2">
          <circle cx="12" cy="12" r="9" />
          <path d="m17.3 9.5358314-5.3 5.0667826-5.3-5.0667826" />
        </svg>
      </Indicator>
    </Root>
  )
}

const Root = styled.div<{ $isOpened: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 48px;
  width: 100%;
  color: ${colors.primary.primary};
  background-color: ${({ $isOpened }) => ($isOpened ? colors.white : colors.primary.lightest)};
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  transition-property: color, background-color;
  user-select: none;

  ${hoverMedia} {
    &:hover {
      color: ${colors.primary.dark};
    }
  }

  &:active {
    color: ${colors.primary.darkest};
  }
`

const Indicator = styled.div<{ $isOpened: boolean }>`
  width: 24px;
  height: 24px;
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
  transform: ${({ $isOpened }) => ($isOpened ? 'rotate(180deg)' : 'none')};
`

const Title = styled.span`
  font-family: ${fontLeagueSpartan};
  font-size: 16px;
  font-weight: 700;
  padding-top: 2px;
  text-transform: uppercase;
`

import React, { useEffect } from 'react'
import { styled } from '@linaria/react'
import { useHits, useStats } from 'react-instantsearch'
import { CustomHit } from './CustomHit'
import { SearchResultsData } from '../types'
import { searchQueryHitsCount } from '../../../../state'
import { colors, desktopSmallMedia, fontLeagueSpartan } from '@/lib/theme'

interface Props {
  sectionName: string
  sectionTitle: string
}
export const CustomHitsSection = ({ sectionName, sectionTitle }: Props) => {
  const { hits } = useHits()
  const { nbHits } = useStats()

  useEffect(() => {
    searchQueryHitsCount.value[sectionName] = Boolean(nbHits)
  }, [nbHits, sectionName])

  if (!nbHits) return null

  return (
    <CustomHitsSectionView>
      <Title>{sectionTitle}</Title>
      <ResultsWrapper>
        {hits.map(hit => (
          <CustomHit key={hit.objectID} hit={hit as unknown as SearchResultsData} />
        ))}
      </ResultsWrapper>
    </CustomHitsSectionView>
  )
}

const CustomHitsSectionView = styled.div`
  display: grid;
  font-family: ${fontLeagueSpartan};
  grid-row-gap: 24px;

  ${desktopSmallMedia} {
    grid-row-gap: 32px;
  }
`

const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0.02em;
  color: ${colors.tertiary.tertiary};
  text-transform: uppercase;
  cursor: pointer;
`

const ResultsWrapper = styled.div`
  display: grid;
`

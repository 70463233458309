import React from 'react'
import { styled } from '@linaria/react'
import { Index } from 'react-instantsearch'
import { SingleSearchIndex } from '_/config/search/searchIndices'
import { CustomHitsSection } from './components'
import { desktopSmallMedia } from '@/lib/theme'

export const SearchResult = ({ indices }: { indices: SingleSearchIndex[] }) => {
  return (
    <SearchResultView>
      {indices.map(({ name, title }) => (
        <Index key={name} indexName={name}>
          <CustomHitsSection sectionName={name} sectionTitle={title} />
        </Index>
      ))}
    </SearchResultView>
  )
}

const SearchResultView = styled.div`
  display: grid;
  grid-row-gap: 48px;
  
  ${desktopSmallMedia} {
    grid-row-gap: 64px;
  }
`
import React from 'react'
import { styled } from '@linaria/react'
import { Styleable } from '@/lib/interfaces'

type DirectionType = 'left' | 'right'

interface Props extends Styleable {
  size?: number
  color?: string
  direction?: DirectionType
}

export const Arrow = ({ size = 20, color = 'currentColor', className, direction = 'left' }: Props) => {
  return (
    <ArrowContainer $size={size} className={className} $direction={direction}>
      <svg width={size} height={size} viewBox="0 0 20 20" fill={color}>
        <path d="m2.086 10.692 5.906 5.912c.385.385 1.015.388 1.4.004.385-.385.379-1.014-.005-1.398l-4.227-4.226h12.059c.544 0 .992-.44.992-.984s-.448-.985-.992-.985h-12.059l4.226-4.224c.382-.381.365-1.007-.016-1.389-.382-.382-1.001-.382-1.383 0l-5.901 5.905c-.178.178-.29.422-.29.692s.112.515.29.693z" />
      </svg>
    </ArrowContainer>
  )
}

const ArrowContainer = styled.div<{ $size: number; $direction: DirectionType }>`
  display: flex;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  transform: ${({ $direction }) => $direction === 'right' ? 'scaleX(-1)' : 'none'};
`

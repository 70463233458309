import React from 'react'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { colors } from '@/lib/theme'
import { Arrow, Wrapper } from '@/UI'

interface Props {
  text: string
  parentNodePath?: string
}

export const BREADCRUMBS_HEIGHT = 48

export const Breadcrumbs = ({ parentNodePath, text }: Props) => {
  return (
    <Outer>
      <Container $noMobilePadding={false}>
        {parentNodePath ? (
          <StyledLink to={parentNodePath}>
            <ArrowStyled />
            <Text>{text}</Text>
          </StyledLink>
        ) : (
          <Text>{text}</Text>
        )}
      </Container>
    </Outer>
  )
}

const Outer = styled.div`
  background-color: ${colors.background.grayLightest};
  height: ${BREADCRUMBS_HEIGHT}px;
  width: 100%;
  display: flex;
  align-items: center;
`

const Container = styled(Wrapper)`
  display: flex;
  align-items: center;
  color: ${colors.secondary.secondary};
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
`

const ArrowStyled = styled(Arrow)`
  margin-right: 6px;
`

const Text = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.62;
  text-transform: uppercase;
`

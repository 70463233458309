import React from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { searchIndices } from '_/config/search/searchIndices'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { hasSearchResults, isHasSearchQuery, searchQueryState } from '../../../state'
import { SearchResult } from './SearchResult'

export const Suggestions = () => {
  const hasSearchQueryState = useSnapshot(isHasSearchQuery)
  const hasSearchResultsState = useSnapshot(hasSearchResults)
  const searchQuery = useSnapshot(searchQueryState)

  return (
    <Container>
      {hasSearchQueryState.value && !hasSearchResultsState.value && (
        <NoResultsBlock>
          <Text>No Results Found for </Text>
          <QueryName>
            {`"@`}
            {searchQuery.query}
            {`"`}
          </QueryName>
        </NoResultsBlock>
      )}
      {hasSearchQueryState.value && <SearchResult indices={searchIndices} />}
    </Container>
  )
}

const Container = styled.div`
  padding: 48px 0;

  ${desktopSmallMedia} {
    margin: 0 16px;
  }
`

const NoResultsBlock = styled.div`
  color: ${colors.white};
  display: flex;
`
const Text = styled.span`
  margin-right: 6px;
`

const QueryName = styled.div`
  font-weight: bold;
`
